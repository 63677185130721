<template>
  <div class="pt-6">
    <MatrizActasCabeceras :matriz="matrizActaCabecera"></MatrizActasCabeceras>
    <v-row class="my-3 mt-6">
      <v-col cols="6" sm="2" md="2" offset-md="2" offset-sm="1" class="d-flex justify-end align-center py-0">
        <v-switch class="mt-0" dense v-model="opcionSolo" label="Solo yo" color="primary" hide-details></v-switch>
      </v-col>
      <v-col cols="6" sm="3" md="2" class="d-flex align-items justify-end align-center py-0">
        <span
          class="v-input--selection-controls mr-2 mt-0"
          style="color:rgba(0, 0, 0, 0.6); cursor: pointer"
          @click="opcionFiltro = !opcionFiltro"
        >
          Fecha
        </span>
        <v-switch class="mt-0" dense v-model="opcionFiltro" label="Semana" color="primary" hide-details></v-switch>
      </v-col>
      <v-col cols="12" sm="3" md="3" v-if="!opcionFiltro" class="d-flex align-center py-0">
        <v-menu ref="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              hide-details
              v-model="dateRangeText"
              label="Filtro por fechas"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateRange" range locale="es-es" :first-day-of-week="1"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="3" md="3" v-if="opcionFiltro" class="d-flex align-center py-0">
        <v-select
          dense
          class="my-0"
          hide-details
          v-model="semana"
          :items="semanas"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Semana"
          single-line
          multiple
          append-outer-icon="mdi-find-replace"
          @click:append-outer="initialize('S')"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="d-flex align-center py-0">
        <v-select
          class="my-0"
          multiple
          dense
          hide-details
          v-model="status"
          @blur="changeStatus"
          :items="itemsStatusResponsible"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Tipo"
          single-line
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="selectAllStatus" @mousedown.prevent>
              <v-list-item-action>
                <v-icon color="primary">{{ iconCheckboxAll }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> TODOS </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <template v-if="status.length == itemsStatusResponsible.length">
              <span v-if="index === 0">TODOS</span>
            </template>
            <template v-else>
              <span v-if="index > 0">,</span><span>{{ item.text }}</span>
            </template>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="1" md="1" v-auth-acl="'gh-proy-mat_seg-export'" class="py-0 d-flex justify-end align-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small @click="exportarbase" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-cloud-download</v-icon>
            </v-btn>
          </template>
          <span>Exportar BD</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :height="height"
      :headers="headers"
      :items="proyectos"
      class="border"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">PROYECTOS</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
        </div>
      </template>
      <template v-slot:[`expanded-item`]="{ headers, item }">
        <td :colspan="headers.length">{{ headers.length }}More info about {{ item }}</td>
      </template>
      <template v-slot:[`item.responsable`]="{ item }">
        <div style="display: flex;align-items: center;">
          <ul class="common-list my-2">
            <li :key="i" v-for="(e, i) in item.responsable">
              {{ e.name }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:[`item.fecha`]="{ item }">
        {{
          item.fecha
            .split('-')
            .reverse()
            .join('-')
        }}
      </template>
      <template v-slot:[`item.fechafin`]="{ item }">
        {{
          item.fechafin
            .split('-')
            .reverse()
            .join('-')
        }}
      </template>
      <template v-slot:[`item.apoyo`]="{ item }">
        <div style="display: flex;align-items: center;">
          <ul class="common-list my-2">
            <li :key="i" v-for="(e, i) in item.apoyo">
              {{ e.name }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:[`item.messagesubtarea`]="{ item }">
        <v-chip color="blue" dark @click="viewActas(item)">{{ item.messagesubtarea }}</v-chip>
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip v-if="parseInt(item.subio) === 1" :color="item.estado.color" dark>{{ item.estado.text }}</v-chip>
        <v-chip v-else :color="item.estado.color" dark @click="openDialogFile(item)">{{ item.estado.text }}</v-chip>
      </template>
      <template v-slot:[`item.fichero`]="{ item }">
        <v-chip
          v-if="parseInt(item.subio) === 1"
          color="primary"
          dark
          @click="exportar(item)"
          close
          @click:close="eliminarEntregable(item)"
        >
          <v-icon color="white">mdi-cloud-download</v-icon>
          <span :class="['ml-3']">
            {{ item.nombrefichero }}
          </span>
        </v-chip>
        <v-chip v-else color="primary" dark @click="openDialogFile(item)">
          <v-icon color="white">mdi-cloud-upload</v-icon>
        </v-chip>
      </template>
      <template v-slot:[`item.comentario`]="{ item }">
        <v-edit-dialog :return-value.sync="item.comentario" @save="changeComentario(item)">
          {{ item.comentario }}
          <template v-slot:input>
            <v-text-field v-model="item.comentario" label="Comentario" single-line></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:no-data>
        <span>No se encontró información</span>
      </template>
      <template v-slot:[`item.txtresponsable`]> </template>
      <template v-slot:[`item.txtapoyo`]> </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">AVANCE: {{ messagesubtarea }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="selectedStatus"
                  :items="itemsStatus"
                  label="Estado"
                  item-text="text"
                  item-value="value"
                  required
                >
                  <template v-slot:selection="data">
                    <v-chip :color="data.item.color" dark> {{ data.item.text }}</v-chip>
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="statusComplete" cols="12" sm="12">
                <v-file-input
                  :rules="rules"
                  v-model="evidencia"
                  accept=".zip,.rar,.7zip,.pdf,.png,.jpg,.jpeg,.pptx,.docx,.xlsx"
                  show-size
                  label="Evidencia"
                  clearable
                  @change="changeInputFile($event)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
          <small></small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDialogUploadFile">Cerrar</v-btn>
          <v-btn color="primary" text @click="confirmDialogUploadFile">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import MatrizProyectoService from '@/giplan/proyectos/matriz/services/MatrizProyectoService';
import moment from 'moment';
import Swal from 'sweetalert2';
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import MatrizActasCabeceras from '@/giplan/actas/matriz_actas/components/MatrizActasCabeceras';

Vue.use(VeeValidate);

export default {
  $_veeValidate: {
    validator: 'new'
  },
  name: 'MatrizProyectoLista',
  components: {
    MatrizActasCabeceras
    // ActasView
  },
  data: () => ({
    height: 160,
    opcionSolo: false,
    opcionFiltro: false,
    expanded: [],
    search: '',
    semana: [],
    semanas: [],
    menuDateRange: false,
    dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    dateRangeText: `${moment().format('DD-MM-YYYY')} ~ ${moment().format('DD-MM-YYYY')}`,
    dialogActasView: false,
    showLoading: false,
    matrizProyectoService: null,
    dialog: false,
    messagesubtarea: '',
    evidencia: null,
    fechaReprogramacion1: null,
    fechaReprogramacion2: null,
    fechaReprogramacion3: null,
    menuReprogramacion1: false,
    menuReprogramacion2: false,
    menuReprogramacion3: false,
    selectedStatus: 'PV',
    statusComplete: false,
    tipoEscogido: 'F',
    errorFile: false,
    idproyecto: '',
    idtarea: '',
    item: '',
    objectViewActa: {},
    /* status: ['PV'], */
    status: '',
    itemsStatusResponsible: [
      { value: 'RE', text: 'Realizado' },
      { value: 'PV', text: 'Por Vencer' },
      { value: 'NR', text: 'No Realizado' },
      { value: 'CA', text: 'Cancelado' }
    ],
    max100chars: (v) => v.length <= 100 || 'El campo excedio el límite!',
    matrizActaCabecera: {
      re: { color: 'green', title: 'REALIZADO', count: 0, percent: 0 },
      pv: { color: 'blue', title: 'POR VENCER', count: 0, percent: 0 },
      nr: { color: 'red', title: 'NO REALIZADO', count: 0, percent: 0 },
      ca: { color: 'back', title: 'CANCELADO', count: 0, percent: 0 }
    },
    itemsStatus: [
      { value: 'RE', color: 'green', text: 'Realizado' },
      { value: 'PV', color: 'blue', text: 'Por Vencer' },
      { value: 'NR', color: 'red', text: 'No Realizado' },
      { value: 'CA', color: 'back', text: 'Cancelado' }
    ],
    headers: [
      /*{ text: 'N°', value: 'numero', width: '70', class: 'header-items-fixed-pro nth-child1 primary', fixed: true },*/
      {
        text: 'PROYECTO',
        value: 'proyecto',
        width: '210',
        class: 'header-items-fixed-pro nth-child1 primary',
        fixed: true
      },
      { text: 'ETAPA', value: 'etapa', class: 'header-items-fixed-pro nth-child2 primary', width: '210' },
      { text: 'ACTIVIDAD', value: 'actividad', class: 'header-items-fixed-pro nth-child3 primary', width: '210' },
      { text: 'TAREA', value: 'tarea', class: 'header-items-fixed-pro nth-child4 primary', width: '210' },
      { text: 'AVANCE', value: 'avance', width: '150', class: 'primary' },
      { text: 'RESPONSABLES', value: 'responsable', width: '300', class: 'primary' },
      { text: 'APOYO', value: 'apoyo', width: '300', class: 'primary' },
      { text: 'FECHA', value: 'fecha', width: '150', class: 'primary' },
      { text: 'FECHA FIN', value: 'fechafin', width: '150', class: 'primary' },
      { text: 'SEMANA', value: 'subtarea', width: '150', class: 'primary' },
      { text: 'DÍAS RESTANTES', value: 'diasrestantes', width: '180', class: 'primary' },
      { text: 'ESTATUS', value: 'estado', width: '150', class: 'primary' },
      { text: 'FECHA ENTREGABLE', value: 'fechaentregable', width: '200', class: 'primary' },
      { text: 'ENTREGABLE', value: 'fichero', width: '400', class: 'primary' },
      { text: 'COMENTARIO ENTREGABLE', value: 'comentario', width: '300', class: 'primary' },
      { text: 'extra', value: 'txtresponsable', width: '0', class: 'd-none' },
      { text: 'extra', value: 'txtapoyo', width: '0', class: 'd-none' }
      //{ text: 'extra', value: 'txtresponsable', width: '0', class: 'd-none' }
    ],
    rules: [
      (value) => !value || value.size < 25000000 || 'El archivo debe de tener menos de 25 MB!',
      (value) => {
        if (value === null || value === undefined) return true;
        const res = value.name.slice(value.name.lastIndexOf('.') + 1, value.name.length).toLowerCase();
        if (
          res != 'exe' &&
          res != 'sql' &&
          res != 'jar' &&
          res != 'air' &&
          res != 'src' &&
          res != 'vb' &&
          res != 'bat' &&
          res != 'dll' &&
          res != 'com' &&
          res != 'bin' &&
          res != 'apk' &&
          res != 'app' &&
          res != 'cgi' &&
          res != 'wsf' &&
          res != 'gadget' &&
          res != 'log' &&
          res != 'css' &&
          res != 'sqlite' &&
          res != 'psd' &&
          res != 'ai' &&
          res != 'bak' &&
          res != 'js' &&
          res != 'cmd'
        )
          return true;
        return 'No se permite ese tipo de archivos.';
      }
    ],
    proyectos: [],
    tiposeleccionado: ''
  }),
  computed: {
    checkAllStatus() {
      return (this.status?.length ?? 0) === this.itemsStatusResponsible.length;
    },
    checkSomeStatus() {
      return (this.status?.length ?? 0) > 0 && !this.checkAllStatus;
    },
    iconCheckboxAll() {
      if (this.checkAllStatus) return 'mdi-close-box';
      if (this.checkSomeStatus) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    selectedStatus(val) {
      this.evidencia = null;
      this.statusComplete = val === 'RE';
    },
    async dateRange(val) {
      const fecha = [];
      val.forEach((element) => {
        fecha.push(
          element
            .split('-')
            .reverse()
            .join('-')
        );
      });
      if (val.length === 1) {
        this.dateRangeText = `${fecha[0]} ~ `;
      }
      if (val.length === 2) {
        const min = Date.parse(val[0]) < Date.parse(val[1]) ? fecha[0] : fecha[1];
        const max = Date.parse(val[0]) > Date.parse(val[1]) ? fecha[0] : fecha[1];
        this.dateRangeText = `${min} ~ ${max}`;
        await this.initialize('F');
      }
    },
    async opcionFiltro(val) {
      this.semana = [];
      if (val) {
        this.showLoading = true;
        this.semana.push(
          await this.matrizProyectoService.get().execResource('fecha', {
            fecha: moment().format('YYYY-MM-DD')
          })
        );
        this.showLoading = false;
        await this.initialize('S');
      } else {
        await this.initialize('F');
      }
    },
    async opcionSolo() {
      if (this.opcionFiltro) {
        await this.initialize('S');
      } else {
        await this.initialize('F');
      }
    }
  },
  methods: {
    async changeStatus() {
      if (this.opcionFiltro) {
        await this.initialize('S');
      } else {
        await this.initialize('F');
      }
    },
    async eliminarEntregable({ idfichatecnicapy, idtarea, item }) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Desea eliminar el entregable.',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        const res = await this.matrizProyectoService.delete().execResource('eliminarEntregable', {
          idproyecto: idfichatecnicapy,
          idtarea: idtarea,
          idsubtarea: item
        });
        if (res.status) {
          await this.alertDialog('success', res.data);
          await this.initialize(this.tipoEscogido);
        } else {
          await this.alertDialog('error', res.data);
        }
      }
    },
    async semanasItems() {
      const res = await this.matrizProyectoService.get().execResource('fecha', {
        fecha: moment().year() + '-12-31'
      });
      for (let i = 1; i <= res; i++) {
        this.semanas.push(i);
      }
    },
    async exportarbase() {
      const fecha1 = moment().format('YYYYMMDDHHMMSS');
      const nombreentregable = 'EXPORT-MATRIZSEGUIMIENTOPROYECTOS-' + fecha1 + '.xlsx';
      const token = localStorage.getItem('token');
      console.log(this.tiposeleccionado, 'tipo');
      if (this.tiposeleccionado == 'F') {
        await this.matrizProyectoService.downloadResource(
          'exportarproyectos',
          {
            fechaini:
              Date.parse(this.dateRange[0]) < Date.parse(this.dateRange[1]) ? this.dateRange[0] : this.dateRange[1],
            fechafin:
              Date.parse(this.dateRange[0]) > Date.parse(this.dateRange[1]) ? this.dateRange[0] : this.dateRange[1],
            semanas: '',
            estatus: this.status.toString().replaceAll('&', '%26'),
            token: token,
            bandera: this.opcionSolo
          },
          nombreentregable
        );
      }
      if (this.tiposeleccionado == 'S') {
        await this.matrizProyectoService.downloadResource(
          'exportarproyectos',
          {
            fechaini: '',
            fechafin: '',
            semanas: this.semana,
            estatus: this.status.toString().replaceAll('&', '%26'),
            token: token
          },
          nombreentregable
        );
      }
    },
    async initialize(tipo) {
      this.showLoading = true;
      this.proyectos = [];
      this.tiposeleccionado = tipo;
      let res;
      if (tipo == 'F') {
        res = await this.matrizProyectoService.get().execResource('matriz', {
          fechaini:
            Date.parse(this.dateRange[0]) < Date.parse(this.dateRange[1]) ? this.dateRange[0] : this.dateRange[1],
          fechafin:
            Date.parse(this.dateRange[0]) > Date.parse(this.dateRange[1]) ? this.dateRange[0] : this.dateRange[1],
          semanas: '',
          estatus: this.status.toString().replaceAll('&', '%26'),
          bandera: this.opcionSolo
        });
      }
      if (tipo == 'S') {
        res = await this.matrizProyectoService.get().execResource('matriz', {
          fechaini: '',
          fechafin: '',
          semanas: this.semana,
          estatus: this.status.toString().replaceAll('&', '%26'),
          bandera: this.opcionSolo
        });
      }

      if (res.length > 0) {
        res.forEach((el, index) => {
          const responsibles = el.jsonresponsables !== null ? el.jsonresponsables : [];
          const apoyos = el.jsonsoporte !== null ? el.jsonsoporte : [];
          this.proyectos.push({
            numero: index + 1,
            idfichatecnicapy: el.idfichatecnicapy,
            proyecto: el.proyecto,
            etapa: el.etapa,
            actividad: el.actividad,
            idtarea: el.idtarea,
            tarea: el.tarea,
            itemid: el.itemid,
            item: el.item,
            fecha: el.fecha,
            fechafin: el.fechafin,
            subtarea: el.subtarea.substr(-2),
            avance: el.avance,
            responsable: el.jsonresponsables,
            txtresponsable: responsibles.reduce((prev, curr) => `${prev},${curr.name}`, ''),
            txtapoyo: apoyos.reduce((prev, curr) => `${prev},${curr.name}`, ''),
            apoyo: el.jsonsoporte,
            comentario: el.comentarios,
            fichero: el.fichero,
            nombrefichero: el.nombrefichero,
            fechaentregable: el.fechaentregable != null ? moment(el.fechaentregable).format('DD-MM-YYYY HH:mm') : '',
            subio: el.subio,
            diasrestantes: el.diasrestantes,
            estado: this.itemsStatus.filter((elem) => elem.value === el.estado)[0], //{ value: 'PV', color: 'blue', text: 'Por Vencer' },
            class: 'class-items-fixed-pro cell-d-none'
          });
        });
        this.height = 520;
      } else {
        this.proyectos = [];
        this.height = 160;
      }

      this.showLoading = false;

      this.calcularCabecera();
    },
    calcularCabecera() {
      this.cleanEstados();
      const total = this.proyectos.length;

      this.proyectos.forEach((el) => {
        this.switchEstados(el.estado.value, total);
      });
    },
    cleanEstados() {
      this.matrizActaCabecera.re.count = 0;
      this.matrizActaCabecera.re.percent = 0;
      this.matrizActaCabecera.pv.count = 0;
      this.matrizActaCabecera.pv.percent = 0;
      this.matrizActaCabecera.nr.count = 0;
      this.matrizActaCabecera.nr.percent = 0;
      this.matrizActaCabecera.ca.count = 0;
      this.matrizActaCabecera.ca.percent = 0;
    },
    switchEstados(estado, total) {
      switch (estado) {
        case 'RE':
          this.matrizActaCabecera.re.count += 1;
          this.matrizActaCabecera.re.percent = parseFloat(
            (parseFloat(this.matrizActaCabecera.re.count) * parseFloat(100)) / parseFloat(total)
          ).toFixed(0);
          break;
        case 'PV':
          this.matrizActaCabecera.pv.count += 1;
          this.matrizActaCabecera.pv.percent = parseFloat(
            (parseFloat(this.matrizActaCabecera.pv.count) * parseFloat(100)) / parseFloat(total)
          ).toFixed(0);
          break;
        case 'NR':
          this.matrizActaCabecera.nr.count += 1;
          this.matrizActaCabecera.nr.percent = parseFloat(
            (parseFloat(this.matrizActaCabecera.nr.count) * parseFloat(100)) / parseFloat(total)
          ).toFixed(0);
          break;
        case 'CA':
          this.matrizActaCabecera.ca.count += 1;
          this.matrizActaCabecera.ca.percent = parseFloat(
            (parseFloat(this.matrizActaCabecera.ca.count) * parseFloat(100)) / parseFloat(total)
          ).toFixed(0);
          break;
      }
    },

    async changeComentario({ idfichatecnicapy, idtarea, item, comentario }) {
      this.showLoading = true;
      const res = await this.matrizProyectoService.put().execResource('comentario', {
        idproyecto: idfichatecnicapy,
        idtarea: idtarea,
        idsubtarea: item,
        comentario: comentario
      });
      this.showLoading = false;

      if (res.status) {
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
      await this.initialize(this.tipoEscogido);
    },

    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    closeDialogUploadFile() {
      this.messagesubtarea = '';
      this.idproyecto = '';
      this.idtarea = '';
      this.item = '';
      this.evidencia = null;
      this.dialog = false;
    },
    async confirmDialogUploadFile() {
      const file = this.evidencia === null || this.evidencia === undefined ? null : this.evidencia;

      console.log('_>', this.selectedStatus, this.errorFile, file);

      if (this.selectedStatus === 'RE') {
        if (file == null) {
          await this.alertDialog('warning', 'Debe de subir su evidencia para cambiar su estado a "REALIZADO"');
          return;
        }
        if (this.errorFile) {
          await this.alertDialog('warning', 'Tamaño o tipo de archivo no permitido');
          return;
        }
      }

      const formData = new FormData();
      formData.append('idproyecto', this.idproyecto);
      formData.append('idtarea', this.idtarea);
      formData.append('idsubtarea', this.item);
      formData.append('estatus', this.selectedStatus);
      formData.append('file', this.selectedStatus === 'RE' ? file : null);

      this.closeDialogUploadFile();

      this.showLoading = true;
      const res = await this.matrizProyectoService.post().execResource('subirevidencia', formData);
      await this.initialize(this.tipoEscogido);
      if (res.status) {
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    async exportar({ idfichatecnicapy, idtarea, item, nombrefichero }) {
      const token = localStorage.getItem('token');

      await this.matrizProyectoService.downloadResource(
        'descargarevidencia',
        {
          idproyecto: idfichatecnicapy,
          idtarea: idtarea,
          idsubtarea: item,
          token: token
        },
        nombrefichero
      );
    },
    openDialogFile({ idfichatecnicapy, idtarea, item, subtarea, estado }) {
      this.messagesubtarea = `${subtarea}`;
      this.selectedStatus = estado.value;
      this.idproyecto = idfichatecnicapy;
      this.idtarea = idtarea;
      this.item = item;
      this.dialog = true;
    },
    removeFile(codigo) {
      this.proyectos.map((el) => {
        if (el.codigo === codigo) {
          el.entregable = null;
          el.errorEntregable.error = false;
          el.errorEntregable.message = '';
        }
        return el;
      });
    },
    changeInputFile($event) {
      const file = $event === null || $event === undefined ? null : $event;

      if (file === null) {
        return;
      }

      this.errorFile = false;

      if (file.size > 25000000) {
        this.errorFile = true;
      }

      const res = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (
        res == 'exe' ||
        res == 'sql' ||
        res == 'jar' ||
        res == 'air' ||
        res == 'src' ||
        res == 'vb' ||
        res == 'bat' ||
        res == 'dll' ||
        res == 'com' ||
        res == 'bin' ||
        res == 'apk' ||
        res == 'app' ||
        res == 'cgi' ||
        res == 'wsf' ||
        res == 'gadget' ||
        res == 'log' ||
        res == 'css' ||
        res == 'sqlite' ||
        res == 'psd' ||
        res == 'ai' ||
        res == 'bak' ||
        res == 'js' ||
        res == 'cmd'
      ) {
        this.errorFile = true;
      }
    },
    closeActasView() {
      console.log('close close');
      this.objectViewActa = {
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        idarea: '',
        idresponsable: '',
        idtrabajador: '',
        lider: '',
        nombresall: ''
      };
      this.dialogActasView = false;
    },
    async viewActas({ idacta }) {
      this.showLoading = true;
      this.objectViewActa = {
        agendas: [],
        areaescribano: '',
        compromisos: [],
        escribano: '',
        invitados: [],
        idarea: '',
        idresponsable: '',
        idtrabajador: '',
        lider: '',
        nombresall: ''
      };

      const res = await this.matrizProyectoService.get().execResource('viewacta', {
        idacta: idacta
      });
      this.showLoading = false;
      this.objectViewActa = res;
      this.dialogActasView = true;
    },
    openDialogSubtask({ code, text, percent }) {
      this.dialogSubtask = true;
      this.subtaskId = code;
      this.messageDialog = `Añadir Subtarea [ ${text} ~ ${percent}% ]`;
    },
    closeAddSubtask() {
      this.subtaskName = '';
      this.subtaskDescription = '';
      this.subtaskPercent = 0;
      this.subtaskEvidence = null;
      this.subtaskId = '';
      this.messageDialog = '';
      this.dialogSubtask = false;
    },
    async aceptAddSubtask() {
      const res = await this.$validator.validateAll();
      console.log('validadte -> ', res);
    },
    aceptEditSubtask() {},
    increment() {
      if (parseFloat(this.subtaskPercent) < 100 && parseFloat(this.subtaskPercent) >= 0) {
        this.subtaskPercent++;
      }
    },
    decrement() {
      if (parseFloat(this.subtaskPercent) <= 100 && parseFloat(this.subtaskPercent) > 0) {
        this.subtaskPercent--;
      }
    },
    async selectAllStatus() {
      this.$nextTick(() => {
        if (this.checkAllStatus) {
          this.status = [];
        } else {
          this.status = this.itemsStatusResponsible.map((element) => {
            return element.value;
          });
        }
      });
    }
  },
  filters: {
    convertSizeFiles(size) {
      const bytes = parseInt(size);
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '';
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      if (i === 0) return `${bytes} ${sizes[i]}`;
      const res = (bytes / Math.pow(1024, i)).toFixed(2);
      return `(${res} ${sizes[i]})`;
    }
  },
  async mounted() {
    this.$validator.localize('es', this.dictes);
    // this.$validator.reset();
  },
  async created() {
    this.matrizProyectoService = this.$httpService(new MatrizProyectoService(), this);
    await this.selectAllStatus();
    await this.semanasItems();
    await this.initialize(this.tipoEscogido);
  }
};
</script>
<style lang="scss">
.alturaproyec > .v-data-table__wrapper {
  height: calc(100vh - 420px);
}
.header-items-fixed-pro {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-pro.nth-child1 {
  left: 0px !important;
  top: 0;
  z-index: 4 !important;
}
.header-items-fixed-pro.nth-child2 {
  left: 210px !important;
  top: 0;
  z-index: 4 !important;
}
.header-items-fixed-pro.nth-child3 {
  left: 420px !important;
  top: 0;
  z-index: 4 !important;
}
.header-items-fixed-pro.nth-child4 {
  left: 630px;
  border-right: thin solid rgba(250, 250, 250) !important;
  top: 0;
  z-index: 4 !important;
}
.class-items-fixed-pro td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0px;
  z-index: 3;
}
.class-items-fixed-pro td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 210px;
  z-index: 3;
}
.class-items-fixed-pro td:nth-child(3) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 420px;
  z-index: 3;
}
.class-items-fixed-pro td:nth-child(4) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 630px;
  z-index: 3;
}
.cell-d-none td:nth-child(17) {
  display: none;
}
.cell-d-none td:last-child {
  display: none;
}

.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-pro {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-pro {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-pro td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-pro td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-pro td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-pro td:nth-child(2) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-pro td:nth-child(3) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-pro td:nth-child(3) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-pro td:nth-child(4) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-pro td:nth-child(4) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}
/*.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-pro td:nth-child(5) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-pro td:nth-child(5) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}*/

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>
